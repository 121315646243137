import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { NodeInterface } from "../interfaces/blogPost";
import { SiteInterface } from ".";
import {
  ProductBrandInterface,
  ProductCategoryInterface,
  ProductInterface
} from "../interfaces/product";
import { BodyPortableText } from "../interfaces/sanity";
import Shop from "../components/Shop";
import NotFoundPage from "./404";
import { orderInitialProducts } from "../helpers/orderInitialProducts";

export const query = graphql`
  query ProductListQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    products: allSanityProduct(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
    brands: allSanityProductBrand {
      edges {
        node {
          id
          title
        }
      }
    }
    categories: allSanityProductCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    shopSettings: sanityShopSettings {
      title
      _rawShopPageCopy
      topProducts {
        id
      }
    }
  }
`;

interface ShopSettings {
  title: string;
  _rawShopPageCopy: BodyPortableText[];
  topProducts: {
    id: string;
  }[];
}

interface ProductListPageProps {
  data: {
    site: SiteInterface;
    products: {
      edges: NodeInterface<ProductInterface>[];
    };
    brands: {
      edges: NodeInterface<ProductBrandInterface>[];
    };
    categories: {
      edges: NodeInterface<ProductCategoryInterface>[];
    };
    shopSettings: ShopSettings;
  };
  errors: Array<GraphQLError>;
}

const ProductListPage: React.FC<ProductListPageProps> = ({ data, errors }) => {
  const { products, site, shopSettings, brands, categories } = data || {};
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <>
      {site && (
        <SEO
          title={shopSettings.title}
          description={site.description}
          keywords={site.keywords}
        />
      )}
      {process.env.GATSBY_BUILD_SHOP &&
      process.env.GATSBY_BUILD_SHOP === "true" ? (
        <Shop
          products={orderInitialProducts(
            products.edges.map(({ node }) => node),
            shopSettings.topProducts.map(({ id }) => id)
          )}
          brands={brands.edges.map(({ node }) => node)}
          categories={categories.edges.map(({ node }) => node)}
          content={shopSettings._rawShopPageCopy}
          enabledFilters={["categories"]}
        />
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default ProductListPage;
