import { ProductInterface } from "../interfaces/product";
import { removeUndefined } from "./removeUndefined";

export const orderInitialProducts = (
  allProducts: ProductInterface[],
  topProducts: string[]
): ProductInterface[] => {
  const sortedProducts = allProducts.sort((a, b) => {
    if (a.title < b.title) return -1;
    return 1;
  });

  const primaryProducts = topProducts
    .map(id => {
      const product = sortedProducts.find(prod => prod.id === id);
      return product;
    })
    .filter(removeUndefined);

  return [
    ...primaryProducts,
    ...sortedProducts.filter(prod => !topProducts.includes(prod.id))
  ];
};
