import React from "react";
import {
  ProductFilterContextProvider,
  ProductFilterType
} from "../../contexts/ProductFilterContext";
import ProductPreviewList from "../ProductPreviewList";
import {
  ProductBrandInterface,
  ProductCategoryInterface,
  ProductInterface
} from "../../interfaces/product";
import { BodyPortableText } from "../../interfaces/sanity";
import HelenCard from "../HelenCard";
import { styles } from "./styles";

interface ShopProps {
  brands: ProductBrandInterface[];
  products: ProductInterface[];
  categories: ProductCategoryInterface[];
  content?: string | BodyPortableText[];
  enabledFilters: ProductFilterType[];
}

const Shop = ({
  brands,
  products,
  categories,
  content,
  enabledFilters
}: ShopProps) => {
  return (
    <ProductFilterContextProvider
      brands={brands}
      products={products}
      categories={categories}
      enabledFilters={enabledFilters}
    >
      <div css={styles.container}>
        <ProductPreviewList
          title="The Shop"
          headingLevel="h1"
          content={content}
          includeProductHeading={true}
          enableFilters={true}
        />
        <HelenCard />
      </div>
    </ProductFilterContextProvider>
  );
};

export default Shop;
